import React from 'react'
import {createRoot} from 'react-dom/client'
import {} from './style.css'

import Main from './componenet/Main'
const con = document.getElementById('root')
const root = createRoot (con)

root.render(

    <>
    <Main/>
    </>
)