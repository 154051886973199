import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

function Article() {
  const location = useLocation();
  const value = location.state?.value;  // Safeguard if value is undefined
 
  

  return (
    <div dangerouslySetInnerHTML={
      { __html: value  }
   }>
       </div>
  );
}

export default Article;
