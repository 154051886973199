import React, { useState } from 'react'

import Logo from '../img/Logo.png'


import { Link , Routes , Route } from 'react-router-dom'
import About from './About'

import Doc from './Doc'
import Home from './Home'
import Menu from '../img/menu.png'
import Blog from './Blog'
import Apps from './Apps'
import Article from './Article'
function Nav() {
  let [visible , setVisble]=useState(false)
  
   const widthcheck = ()=>{
    
   
   setInterval(() => {
   
    if(window.innerWidth>=1419){
  
      setVisble(false)
      
      
     }else{
  
    
     }
    
   }, 500);
   
   }
   widthcheck()
 

  
  return (
    <>
    <div className='Header'>
      <div className="logo">
      <img src={Logo} alt=""  width={'100px'}/>
     </div>
     
     <div>
     <button className="btn" onClick={()=>{console.log('cliked');setVisble(!visible);console.log(visible);
     }}><img src={Menu} alt="" width={'50px'} className="menu"/></button>
     </div>
   

    <div className='menucontainer'>

  
     <div className="nav">
      <Link to='/'><h3>Home</h3></Link>
      <Link to='/Doc'><h3>Doc</h3></Link>
      <Link to='/Blog'><h3>Blog</h3></Link>
      <Link to='/About'><h3>About</h3></Link>
      <Link to='/Apps'><h3>Apps</h3></Link>
     </div>
     <div className="sing">
     <button>sing in</button>
     <button>Sing Up</button>
     </div>
     </div>
     </div>
    


     <div className='menucontainerSmollScreen'  style={{display:visible?"block":"none"}}>

  
<div className="nav">
 <Link to='/'><h3 onClick={()=>{setVisble(false);
 }}>Home</h3></Link>
 <Link to='/Doc'><h3 onClick={()=>{setVisble(false);
 }}>Doc</h3></Link>
 <Link to='/Blog'><h3 onClick={()=>{setVisble(false);
 }}>Blog</h3></Link>
 <Link to='/About'><h3 onClick={()=>{setVisble(false);
 }}>About</h3></Link>
 <Link to='/Apps'><h3 onClick={()=>{setVisble(false);
 }}>Apps</h3></Link>
</div>
<div className="sing">
<button>sing in</button>
<button>Sing Up</button>
</div>
</div>




    <div className="line">
    
    </div>
    <Routes>
    <Route  path="/" element={<Home/>}> </Route>
       <Route  path="/Doc" element={<Doc/>}> </Route>
       <Route  path="/Blog" element={<Blog/>}> </Route>
       <Route  path="/About" element={<About/>}> </Route>
       <Route  path="/Apps" element={<Apps/>}> </Route>
       <Route path='/Article' element={<Article/>}></Route>
       </Routes>
    </>
  )
}

export default Nav;