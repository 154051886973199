import React, { useEffect, useState } from 'react'

function Apps() {
  const [dowload,setDownload] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
useEffect(()=>{
  
})
  const styles={
  downloadStyle:{
    height: '400px',
    width:'70%',
      borderRadius: '10px',
    backgroundColor: '#ccc',
    display: 'grid',
    gap: '20px',
   margin: 'auto',
   marginTop: '100px',
   display: dowload?'block':'none',
 
  }
}
  return (
    <div className='AppsPage'>
        
        <div className='apps' onClick={()=>{setDownload(true)}}>
          <div className='app'> app one</div>
          <div className='app'> app one</div>
          <div className='app'> app one</div>
          <div className='app' > app one</div>
        </div>
        <div className='download' style={styles.downloadStyle}>
          <h1 onClick={()=>{setDownload(false)}}>X</h1>
        </div>
    </div>
  )
}

export default Apps