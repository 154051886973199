import React from 'react';

function About() {
  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      color: '#333',
      padding: '20px',
      backgroundColor: '#f8f9fa',
      position: 'absolute',
      top:'130px',
      zIndex:-1
    },
    header: {
      backgroundColor: '#1e88e5',
      color: '#fff',
      padding: '20px',
      textAlign: 'center',
    },
    section: {
      marginBottom: '40px',
    },
    title: {
      color: '#1e88e5',
      textAlign: 'center',
      fontSize: '28px',
      marginBottom: '10px',
    },
    text: {
      fontSize: '16px',
      lineHeight: '1.8',
      maxWidth: '800px',
      margin: '0 auto',
      textAlign: 'center',
    },
    services: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-around',
      marginTop: '20px',
    },
    card: {
      backgroundColor: '#fff',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '20px',
      width: '300px',
      margin: '10px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    cardTitle: {
      color: '#333',
      fontSize: '20px',
      marginBottom: '10px',
    },
    cardText: {
      fontSize: '14px',
      lineHeight: '1.6',
    },
    footer: {
      backgroundColor: '#1e88e5',
      color: '#fff',
      textAlign: 'center',
      padding: '10px',
    },
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>About Us</h1>
      </header>
      <main>
        {/* About Section */}
        <section style={styles.section}>
          <h2 style={styles.title}>Who We Are</h2>
          <p style={styles.text}>
            We are a leading system development company dedicated to delivering innovative solutions in mobile app creation, 
            web development, APIs, and custom software packages. Our expertise helps businesses achieve their goals through cutting-edge technology.
          </p>
        </section>

        {/* Services Section */}
        <section style={styles.section}>
          <h2 style={styles.title}>Our Services</h2>
          <div style={styles.services}>
            <div style={styles.card}>
              <h3 style={styles.cardTitle}>Mobile App Development</h3>
              <p style={styles.cardText}>
                We build user-friendly mobile applications for Android and iOS, designed to meet your business needs.
              </p>
            </div>
            <div style={styles.card}>
              <h3 style={styles.cardTitle}>Web Development</h3>
              <p style={styles.cardText}>
                Our team creates responsive and visually stunning websites to enhance your online presence.
              </p>
            </div>
            <div style={styles.card}>
              <h3 style={styles.cardTitle}>API Development</h3>
              <p style={styles.cardText}>
                We specialize in developing secure and efficient APIs to connect your applications seamlessly.
              </p>
            </div>
            <div style={styles.card}>
              <h3 style={styles.cardTitle}>Custom Packages</h3>
              <p style={styles.cardText}>
                Tailored software solutions designed to address your unique challenges and drive success.
              </p>
            </div>
          </div>
        </section>

        {/* Mission Section */}
        <section style={styles.section}>
          <h2 style={styles.title}>Our Mission</h2>
          <p style={styles.text}>
            Our mission is to empower businesses by delivering reliable and scalable solutions through innovative 
            technologies and a commitment to excellence.
          </p>
        </section>
      </main>
      <footer style={styles.footer}>
        <p>&copy; 2024 DDIV. All Rights Reserved.</p>
      </footer>
    </div>
  );
}

export default About;
