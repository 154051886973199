

import Logo from '../img/Logo.png'
import Application from '../img/Application.png'
import Api from '../img/Api.png'
import Code from '../img/Code.png'
function Home() {
const apitset =async()=>{
  try {
    const req = await fetch('http://localhost:9000/')
   console.log("res ponse is ",req);
  } catch (error) {
    console.log('error happend');
    
  }

}
  apitset()
  return (
  
    <>
   
<div className='container'>

  <h1 style={{fontSize:50,textAlign:'center',}}>       <br/>  
  The Ultimate Destination forAPIs, <br/>                  
            Mobile Apps, <br/>   
 and   JavaScript   Libraries 
  </h1>
   
 <h2 style={{fontSize:20,textAlign:'center',marginTop:'30px'}}>Discover unmatched efficiency with our advanced DDIV API, <br/> 
  offering seamless integration,  <br/> 
  ultra-fast responses,  <br/> 
  and cost-effective solutions tailored for developers and businesses alike.</h2>
      <img src={Logo} alt="" className='LogoHome'/>
      <h1 style={{fontSize:50,textAlign:'center', marginTop:'30px',fontStyle:'normal',textDecoration: 'underline',}}>Our Services</h1>
      <h3 style={{textAlign:'center',marginTop:'60px',fontSize:30,}}>mobile apps</h3>
      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
     <img src={Application} alt="" style={{width:'15%'}}/>
      </div>
      <h3 style={{textAlign:'center',marginTop:'60px',fontSize:30,}}>API’S</h3>
      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
     <img src={Api} alt="" style={{width:'15%',}}/>
      </div>
      <h3 style={{textAlign:'center',marginTop:'60px',fontSize:30,}}>JAVASCRIPT LIBRARY</h3>
      <div style={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
     <img src={Code} alt="" style={{width:'15%',}}/>
      </div>
      <h1 style={{fontSize:50,textAlign:'center', marginTop:'70px',fontStyle:'normal',textDecoration: 'underline',}}>Why Choose DDIV?</h1>
      <h3 style={{textAlign:'center',marginTop:'50px'}}>Blazing-Fast API Solutions</h3>
      <div className='homeBoxes'>
       <h2 style={{textAlign:'center',marginTop:'50px',fontSize:20,fontStyle:'normal',fontWeight:29}}>Build smarter systems with our highly optimized APIs <br/>that deliver real-time results and integrate seamlessly into your projects.</h2>
      </div>
      <h3 style={{textAlign:'center',marginTop:'50px'}}>Blazing-Fast API Solutions</h3>
      <div className='homeBoxes'>
      <h2 style={{textAlign:'center',marginTop:'50px',fontSize:20,fontStyle:'normal',fontWeight:29}}>Transform your ideas into dynamic, scalable, and user-friendly mobile apps that stand out in today’s competitive market..</h2>
     
      </div>
      <h3 style={{textAlign:'center',marginTop:'50px'}}>Blazing-Fast API Solutions</h3>
      <div className='homeBoxes'>
      <h2 style={{textAlign:'center',marginTop:'50px',fontSize:20,fontStyle:'normal',fontWeight:29}}>Accelerate your coding with lightweight, efficient, and developer-friendly JavaScript libraries designed to simplify complex tasks.</h2>
     
      </div>
       </div> 
       
      
        </>
   
  )
}

export default Home