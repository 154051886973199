import React from 'react'
import { BrowserRouter } from 'react-router-dom'


import Nav from './Nav'
function Main() {
  return (
   <BrowserRouter>
  <Nav/>
   
   </BrowserRouter>
  )
}

export default Main