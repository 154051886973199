import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
function Blog() {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  const [posts, setPosts] = useState([]);
 const [des,setDes]= useState();
 const [readdes,setReaddes]= useState(false);
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "Blog"));
        const postsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPosts(postsData);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchPosts();
  }, [db]);

  // Styles
  const containerStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px',
    height: 'inherit',
    width: '100%',
    color: 'black',
    zIndex: '-1',
    position: 'absolute',
    top: '100px',
   
  };

  const postStyle = {
    width: '350px', // Smaller card size
    backgroundColor: 'white',
    borderRadius: '10px',
    margin: '20px 10px', // Equal spacing on left and right
    boxShadow: '0 4px 12px rgba(0,0,0,0.3)', // Shadow added
    height: '50vh',
    padding: '15px',
    overflow: 'hidden',
    transition: 'transform 0.3s ease',
    height: '310px',
    
  };

  const imageStyle = {
    width: '100%',
    height: '200px', // Fixed height for consistent image size
    objectFit: 'cover', // Ensures images are cropped uniformly
    borderRadius: '8px',
    marginBottom: '10px',
  };

  const titleStyle = {
    fontSize: '1.3rem',
    fontWeight: '600',
    marginBottom: '10px',
    color: 'black', 
  };

  const contentStyle = {
    fontSize: '1rem',
    lineHeight: '1.5',
    color: 'black', 
  };

  const dividerStyle = {
    borderTop: '1px solid #e0e0e0', // Horizontal divider
    margin: '10px 0', // Equal spacing on left and right
    color: 'black', 
  };

  const authorStyle = {
    fontSize: '0.9rem',
    color: 'black', // Softer color for author name
    fontStyle: 'italic', // Optional for emphasis
  };
  const postdiv ={
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
    padding: '20px',
    height: 'inherit',
    width: '100%',
    color: 'black',
    zIndex: '-1',
    position: 'absolute',
    
   

  }
  const describe = {
  height:'inherit',
  width:'100%',
  backgroundColor:'rgba(255, 255, 255, 0.5)',
  position: 'absolute',
  display:readdes?'block':'none',
  overflow:'auto',
  
  margin:'15px'
  }

  return (
    <div style={containerStyle}>
     
      <div style={postdiv}>
      {posts.map((post) => (
     <Link  to="/Article"  key={post.id} state={{value:post.content}}>
         <div
          key={post.id}
          style={postStyle}
          onMouseEnter={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
          onMouseLeave={(e) => (e.currentTarget.style.transform = 'scale(1)')}
          onClick={()=>{
            setReaddes(true);
            console.log(post.content.length);
            setDes(post.content)
            
          }}>
         
          <img src={post.image} alt={post.title} style={imageStyle} />
          <h2 style={titleStyle}>{post.title}</h2>
          <p style={contentStyle}>{post.content}</p>
          <hr style={dividerStyle} />
          <p style={authorStyle}>By: {post.data}</p>
           {/* Displaying the author */}
        </div>
        </Link>
      ))}</div>
      
     
    </div>
  );
}

export default Blog;
